import React from 'react';

import { Homepage } from 'components/homepage';
import { CONFIG } from 'constants/config';
import { useHomepage } from 'hooks/data/useHomepage';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';

import type { IHomepageSlot } from 'types';

const HomepageTemplate = () => {

  // Data Hooks

  // --- Full Homepage (prefetched)

  const { data } = useHomepage({ isPersonalized: false }, {
    staleTime: Infinity,
    refetchOnWindowFocus: false
  });

  // Refs

  const slotsRef = React.useRef<IHomepageSlot[]>(data?.slots || []);

  // Hooks

  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentStartIndex, setCurrentStartIndex] = React.useState(0);

  const [currentSlots, setCurrentSlots] = React.useState<IHomepageSlot[]>(data?.slots || []);

  // Data Hooks

  // --- Authentication

  const { isSettled: isAuthenticationSettled, isAuthenticated, } = useIsAuthenticated();

  // --- Paginated Homepage

  const { data: paginatedData } = useHomepage({
    isPersonalized: isAuthenticated,
    page: currentPage,
    limit: CONFIG.PAGINATION.HOMEPAGE,
    nextStartIndex: currentStartIndex,
  }, {
    enabled: isAuthenticationSettled,
    refetchOnWindowFocus: false
  });

  // Handlers

  const onWindowFocus = () => {
    setCurrentStartIndex(0);
    setCurrentPage(1);
  };

  // Effects

  // --- Initialize slots

  React.useEffect(() => {
    if (!data) return;
    slotsRef.current = data?.slots;
  }, [data]);

  // --- Handle paginated data

  React.useEffect(() => {
    if (!paginatedData) return;

    const {
      slots: paginatedSlots,
      page,
      limit,
      nextStartIndex,
      hasMore
    } = paginatedData || {};

    if (!page || !limit || !nextStartIndex) return;

    paginatedSlots.forEach((slot, index) => {
      const updateIndex = (page - 1) * limit + index;
      slotsRef.current[updateIndex] = slot;
    });

    if (hasMore) {
      setCurrentPage((previousPage) => previousPage + 1);
      setCurrentStartIndex(nextStartIndex);
    }

    setCurrentSlots(slotsRef?.current);
  }, [paginatedData]);

  // --- Handle window focus

  React.useEffect(() => {
    window.addEventListener('focus', onWindowFocus);
    return () => {
      window.removeEventListener('focus', onWindowFocus);
    };
  }, []);

  // Render

  return (
    <Homepage
      slots={currentSlots}
    />
  );
};

// Export

export default HomepageTemplate;
