import React from 'react';

import { Layout } from 'components/layout';
import { TRANSLATIONS } from 'constants/translations';
import { prefetchCategories, prefetchConfig } from 'helpers/ServerHelpers';
import { withSSGPage } from 'hoc/WithServerPage/withSSGPage';
import { prefetchHomepage } from 'hooks/data/useHomepage';
import HomepageTemplate from 'templates/Home/HomepageTemplate';
import styles from 'templates/Home/HomepageTemplate.module.scss';

import type { NextPageWithLayout } from 'types';
import { PageType } from 'types';

export const getStaticProps = withSSGPage(async (context, props) => {

  const { queryClient } = props;

  await Promise.all([
    prefetchHomepage(queryClient),
    prefetchCategories(queryClient),
    prefetchConfig(queryClient)
  ]);

  return {
    props: {}
  };

}, {
  translations: [TRANSLATIONS.COMMON, TRANSLATIONS.HOME, TRANSLATIONS.RECIPES],
  pageType: PageType.Home,
  revalidate: 10
});

const Page: NextPageWithLayout<PageType.Home> = () => {
  return <HomepageTemplate />;
};

// --- Layout

const layoutProps = {
  className: styles.layout,
  containerClassName: styles.layoutContainer,
  header: {
    isVisible: true,
    props: {
      hasInlineSearchBar: true,
    }
  }
};

Page.getLayout = (page) => {
  return (
    <Layout {...layoutProps}>
      {page}
    </Layout>
  );
};

export default Page;
